<template>
  <div :class="priceClasses">
    <div class="price__name">
      <p class="price__name--left" :style="nameStyles">{{ priceNameLeft }}</p>
      <p class="price__name--right">{{ priceNameRight }}</p>
    </div>
    <div class="price__value">
      <p class="price__value--left" :style="valueStyles">
        <BaseCurrency>{{ priceValueLeft }}</BaseCurrency>
      </p>
      <p class="price__value--right">
        <BaseCurrency>{{ priceValueRight }}</BaseCurrency>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue';
import { computed, toRefs } from 'vue';
import type { IPropsDropSkinPrice } from './DropSkinPrice.types';
import { Colors } from '~/constants/colors.constants';

const props = withDefaults(defineProps<IPropsDropSkinPrice>(), {
  colorBackground: Colors.ADDITIONAL.TD_YELLOW,
  colorText: Colors.MAIN.GOLD,
  priceNameLeft: '',
  priceNameRight: '',
  size: 'default',
});

const { priceNameLeft, priceNameRight, priceValueLeft, priceValueRight, size, colorBackground, colorText } =
  toRefs(props);

const priceClasses = computed(() => ({
  'price': true,
  'price--small': size.value === 'small',
}));

const valueStyles = computed<CSSProperties>(() => {
  const properties: CSSProperties = {};

  properties.background = colorBackground.value;
  properties.color = colorText.value;

  return properties;
});

const nameStyles = computed<CSSProperties>(() => {
  const properties: CSSProperties = {};
  properties.color = colorText.value;

  return properties;
});
</script>

<style src="./DropSkinPrice.scss" scoped></style>
